import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import Background from "../components/background"
import MainBanner from "../components/main-banner"
import PaperlessAwardsVideo from "../components/paperless-awards-video"
import HeadlineSponsors from "../components/headline-sponsors"

const IndexPage = () => (
  <Background>
  <Layout>
    <Seo title="Smarter Working Live - Conference and Awards" description="Celebrating Innovation Through Community, Collaboration and Creativity" />
    <Container>
      <MainBanner />
      <div style={{
        marginTop: `-0px`
      }}>
      <div style={{
        margin: `5rem 0`
      }}>
        {/* <HeadlineSponsors /> */}
      </div>
      </div>
      {/* <PageTitle text="Our Judges" />
      <Judges /> */}
      {/* <div style={{
        // maxWidth: `968px`,
        margin: `5rem auto`
      }}>
        <div className="my-5">
          <PaperlessAwardsVideo title="What to Expect?" />
        </div>
      </div> */}
      <br /><br /><br />
    </Container>
  </Layout>
  </Background>
)

export default IndexPage
