import React from "react"

const SponsorBox = ({children, bgColor, fontColor, padding, border}) => (
  <div style={{
    backgroundColor: bgColor,
    border: border,
    width: `100%`,
    paddingTop: `100%`,
    position: `relative`
  }}>
    <div style={{
      position: `absolute`,
      top: `0`,
      left: `0`,
      bottom: `0`,
      right: `0`,
      padding: `1rem`,
      color: fontColor,
      fontFamily: `Din Pro`,
      fontWeight: `bold`,
      textTransform: `uppercase`,
      textAlign: `center`
    }} className="d-flex">
      <div className="align-self-center" style={{width: `100%`, padding: padding}}>
        {children}
      </div>
    </div>
  </div>
)

SponsorBox.defaultProps = {
  bgColor: `#fff`,
  fontColor: `#000`,
  padding: `0`,
  border: `0`,
}

export default SponsorBox
