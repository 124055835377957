import PropTypes from "prop-types"
import React from "react"

const HeaderSubtitle = ({ text }) => (
  <h2 style={{
    color: `#fff`,
    fontFamily: `Avenir`,
    fontWeight: `300`,
    fontSize: `3.5rem`
  }}>{text}</h2>
)

HeaderSubtitle.propTypes = {
  text: PropTypes.string,
}

HeaderSubtitle.defaultProps = {
  text: `Conference & Awards`,
}

export default HeaderSubtitle