import PropTypes from "prop-types"
import React from "react"

const HeaderDate = ({ text }) => (
  <h4 style={{
    textTransform: `uppercase`,
    color: `#fff`,
    fontFamily: `Din Pro`,
    fontSize: `2rem`
  }}>{text}</h4>
)

HeaderDate.propTypes = {
  text: PropTypes.string,
}

HeaderDate.defaultProps = {
  text: ``,
}

export default HeaderDate