import PropTypes from "prop-types"
import React from "react"

const HeaderTitle = ({ text }) => (
  <h1 style={{
    color: `#fff`,
    fontFamily: `Avenir`,
    fontWeight: `900`,
    fontSize: `3.5rem`
  }}>{text}</h1>
)

HeaderTitle.propTypes = {
  text: PropTypes.string,
}

HeaderTitle.defaultProps = {
  text: `Smarter Working Live`,
}

export default HeaderTitle