import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"

// import GPA from "../images/gpa-partner.png"
// import GovNews from "../images/gnd-partner-v2.png"
import Bytes from "../images/bytes-official-partner.png"
import Microsoft from "../images/microsoft-official-partner.png"
import Copilot from "../images/copilot-official-partner.png"

const SupportBanner = ({children, bgColor, fontColor, padding, border}) => (
  <div className="row" style={{
    // maxWidth: `35.5rem`,
    // maxWidth: `25.5rem`,
    maxWidth: `40rem`
    // maxWidth: `15rem`,
  }}>
    <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid" /></OutboundLink></div>
    <div className="col"><img src={Microsoft} className="img-fluid" /></div>
    <div className="col"><img src={Copilot} className="img-fluid" /></div>
    
    {/* <div hidden className="col"><OutboundLink href="https://www.gov.uk/government/organisations/government-property-agency" target="_blank"><img src={GPA} className="img-fluid" /></OutboundLink></div> */}
    {/* <div hidden className="col"><OutboundLink href="https://www.gov.news/" target="_blank"><img src={GovNews} className="img-fluid" /></OutboundLink></div> */}
    {/* <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Headline} className="img-fluid" /></OutboundLink></div> */}
  </div>
)

SupportBanner.defaultProps = {
  bgColor: `#fff`,
  fontColor: `#000`,
  padding: `0`,
  border: `0`,
}

export default SupportBanner
