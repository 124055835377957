import PropTypes from "prop-types"
import React from "react"

const HeaderStrapline = ({ text }) => (
  <div>
  <h3 className="d-none d-sm-block" style={{
    textTransform: `uppercase`,
    fontFamily: `Din Pro`,
    fontWeight: `bold`,
    fontSize: `1.22rem`,
    lineHeight: `3.4rem`
  }}><span style={{
    backgroundColor: `#30c4bf`,
    color: `#fff`,
    padding: `8px 15px`
    }}>Celebrating Innovation Through Community</span> <br /><span style={{
      backgroundColor: `#30c4bf`,
      color: `#fff`,
      padding: `8px 15px`
      }}>Collaboration and Creativity</span></h3>

  <h3 className="d-block d-sm-none" style={{
    textTransform: `uppercase`,
    fontFamily: `Din Pro`,
    fontWeight: `bold`,
    fontSize: `1.22rem`,
    lineHeight: `3.4rem`
  }}><span style={{
    backgroundColor: `#30c4bf`,
    color: `#fff`,
    padding: `8px 15px`
    }}>Celebrating Innovation Through</span> <br /><span style={{
      backgroundColor: `#30c4bf`,
      color: `#fff`,
      padding: `8px 15px`
      }}>Community, Collaboration and Creativity</span></h3>
  </div>
)

HeaderStrapline.propTypes = {
  text: PropTypes.string,
}

HeaderStrapline.defaultProps = {
  text: ``,
}

export default HeaderStrapline