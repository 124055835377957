import React from "react"

const BandsCircle = ({style, size}) => (
  <div style={style}>
    <div style={{
      width: size,
      height: size,
      background: `repeating-linear-gradient(-45deg, #fff, #fff 4px, transparent 4px, transparent 10px)`,
      borderRadius: `50%`
    }} />
  </div>
)

BandsCircle.defaultProps = {
  size: `100px`,
}

export default BandsCircle